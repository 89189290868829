import { CheckCircleOutlineOutlined } from "@mui/icons-material";
import { Box, Button, Collapse, LinearProgress } from "@mui/material";
import React, { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Content from "../../components/layout/Content";
import ContentHeader from "../../components/layout/ContentHeader";
import ConfirmCodeStep from "./steps/ConfirmCodeStep";
import InsertNewPass from "./steps/InsertNewPass";
import SendCodeButton from "./steps/SendCodeStep";

function ForgotPassword(props) {
  document.title = "Redefinir senha | NotePay";
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();

  const fromReactNative = (searchParams.get("fromReactNative") || "") === "1";
  const email = searchParams.get("email") || "";
  const step = searchParams.get("step") || "0";
  const code = searchParams.get("code") || "";

  const handleGoBackLogin = () => {
    if (fromReactNative) {
      const reactNativeMessage = {
        type: "forgot-password",
        payload: {
          email,
        },
      };
      window.ReactNativeWebView?.postMessage(
        JSON.stringify(reactNativeMessage)
      );
    } else {
      navigate(`/${email ? `?email=${email}&step=1` : ""}`, {
        replace: true,
      });
    }
  };

  useEffect(() => {
    if (
      step &&
      (!["0", "1", "2", "3"].includes(step) || (step === "2" && !code))
    ) {
      searchParams.set("step", "0");
      setSearchParams(searchParams);
    }
  }, []);

  return (
    <>
      <Content
        showGoBack={!fromReactNative}
        onGoBack={() => {
          if (step === "0") {
            handleGoBackLogin();
            return;
          }
          searchParams.set("step", parseInt(step - 1).toString());
          setSearchParams(searchParams);
        }}
      >
        <Box
          mt={1}
          mb={2}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <LinearProgress
            sx={{ width: 100 }}
            variant="determinate"
            value={parseInt(step) * 33}
          />
        </Box>
        <Collapse unmountOnExit in={step === "0"}>
          <SendCodeButton
            email={email}
            onNext={(email) => {
              searchParams.set("email", email);
              searchParams.set("step", "1");
              setSearchParams(searchParams);
            }}
          />
        </Collapse>
        <Collapse unmountOnExit in={step === "1"}>
          <ConfirmCodeStep
            email={email}
            onNext={(code) => {
              searchParams.set("step", "2");
              searchParams.set("code", code);
              setSearchParams(searchParams);
            }}
          />
        </Collapse>
        <Collapse unmountOnExit in={step === "2"}>
          <InsertNewPass
            email={email}
            code={code}
            onNext={() => {
              searchParams.set("step", "3");
              setSearchParams(searchParams);
            }}
          />
        </Collapse>
        <Collapse in={step === "3"}>
          <ContentHeader
            iconColor={"success"}
            Icon={CheckCircleOutlineOutlined}
            title="Senha alterada com sucesso"
            subtitle="Volte ao login para acessar sua conta"
          />
          <Box mt={4} display={"flex"} justifyContent={"center"}>
            <Button
              onClick={handleGoBackLogin}
              variant="contained"
              disableElevation
              size="large"
            >
              Voltar para o login
            </Button>
          </Box>
        </Collapse>
      </Content>
    </>
  );
}

export default ForgotPassword;

const isStaging =
  process.env.REACT_APP_DEBUG === "true" ||
  process.env.REACT_APP_ENV === "staging";

const isDev = process.env.REACT_APP_ENV === "development";

export const BASE_URL = isStaging
  ? "https://auth.notepay.io"
  : `${window.location.origin}`;

export const API_BASE_URL = isStaging
  ? "https://auth.notepay.io/auth/v1"
  : `${window.location.origin}/auth/v1`;

export const APP_BASE_URL = isStaging
  ? "https://app.notepay.io"
  : window.location.origin.replace("auth", "app");

export const WSS_URL = isDev
  ? "ws://localhost:7500"
  : isStaging
  ? "wss://ws.notepay-dev.com"
  : `wss://${window.location.hostname.replace("app", "ws")}`;

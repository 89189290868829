import { ArrowBack } from "@mui/icons-material";
import { Box, IconButton } from "@mui/material";
import React from "react";

function Content({ children, onGoBack, showGoBack }) {
  return (
    <Box p={{ sm: "10px 40px 40px 40px", xs: 1 }} zIndex={1000}>
      {showGoBack && (
        <IconButton
          onClick={onGoBack}
          sx={{
            position: "absolute",
            top: { xs: 5, sm: 10 },
            left: { xs: -10, sm: 10 },
          }}
        >
          <ArrowBack />
        </IconButton>
      )}
      {children}
    </Box>
  );
}

export default Content;

import { Button, Stack, Typography } from "@mui/material";
import React from "react";
import Lottie from "react-lottie";

const SuccessStep = ({ name, email, fromReactNative }) => {
  return (
    <Stack py={5} alignItems={"center"}>
      <Lottie
        style={{ height: "250px", marginTop: "-80px" }}
        options={{
          animationData: require("../../../assets/lotties/completed.json"),
          autoplay: true,
          loop: false,
        }}
        speed={0.3}
        isClickToPauseDisabled
      />
      <Typography
        mt={-2}
        color="textSecondary"
        fontWeight={500}
        textAlign={"center"}
        fontSize={"1.1rem"}
        gutterBottom
      >
        Tudo certo, {name}.
      </Typography>
      <Typography
        fontWeight={500}
        textAlign={"center"}
        fontSize={"1.3rem"}
        gutterBottom
      >
        Sua solicitação foi recebida! 🎉
      </Typography>
      <Typography
        mt={1}
        fontWeight={600}
        textAlign={"center"}
        fontSize={"2rem"}
        variant="h1"
        color="primary"
        gutterBottom
      >
        Estamos preparando o seu teste
      </Typography>
      <Typography
        mt={1}
        fontWeight={500}
        textAlign={"center"}
        fontSize={"1rem"}
        gutterBottom
      >
        Um especialista entrará em contato para garantir que você aproveite ao
        máximo sua experiência durante o teste.
      </Typography>
      {!fromReactNative && (
        <Button
          LinkComponent={"a"}
          href="https://notepay.com"
          variant="outlined"
          sx={{ mt: 3, fontSize: "18px", height: 50 }}
        >
          Ir para o site
        </Button>
      )}
    </Stack>
  );
};

export default SuccessStep;

import React, { useEffect, useState } from "react";
import NextButton from "../../../components/buttons/NextButton";
import TextButton from "../../../components/buttons/TextButton";
import EmailInput from "../../../components/inputs/EmailInput";
import TextInput from "../../../components/inputs/text-input";
import ActionsFooter from "../../../components/layout/ActionsFooter";

const NameStep = ({
  initialName,
  fromReactNative,
  initialLastname,
  initialEmail,
  onSubmit,
  onPrev,
}) => {
  const [name, setName] = useState(initialName || "");
  const [lastname, setLastname] = useState(initialLastname || "");
  const [email, setEmail] = useState(initialEmail || "");

  useEffect(() => {
    setName(initialName);
    setLastname(initialLastname);
    setEmail(initialEmail);
  }, [initialName, initialLastname, initialEmail]);

  const [errors, setErrors] = useState({
    name: null,
    lastname: null,
    email: null,
  });

  const setError = (error, value) => {
    setErrors((prev) => ({
      ...prev,
      [error]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    onSubmit(name?.trim(), lastname?.trim(), email?.trim());
  };

  return (
    <form onSubmit={handleSubmit}>
      <TextInput
        autoFocus
        value={name}
        onFocus={() => setError("name", null)}
        onChange={setName}
        margin={"dense"}
        label={"Primeiro nome"}
        helperText={errors.name || ""}
        error={Boolean(errors.name)}
        required
      />
      <TextInput
        value={lastname}
        onChange={setLastname}
        margin={"normal"}
        label={"Último nome"}
      />
      <EmailInput
        value={email}
        onChange={setEmail}
        error={errors.email}
        clearError={() => setError("email", null)}
      />
      <ActionsFooter
        rightContent={<NextButton />}
        leftContent={
          !fromReactNative && (
            <TextButton onClick={onPrev}>Voltar para login</TextButton>
          )
        }
      />
    </form>
  );
};

export default NameStep;

import axios from "axios";
import { API_BASE_URL } from "./urls";

export class AuthClient {
  static _client = null;
  static client() {
    if (!this._client) {
      this._client = axios.create({
        baseURL: API_BASE_URL,
        withCredentials: true,
        // maxRedirects: 1,
        // beforeRedirect: (options, response) => {
        //   if (response.status === 301 || response.status === 302) {
        //     window.location.href = response.headers.location;
        //   }
        // },
      });
    }
    return this._client;
  }
  static setAccessToken(token) {
    this._client.defaults.headers["api-access-token"] = token;
  }
}

export class AuthAPIGateway {
  static async refreshToken(account = undefined) {
    const r = await AuthClient.client().get("/refresh_token", {
      account,
    });
    const d = r.data;
    if (d.accessToken) {
      AuthClient.setAccessToken(d.accessToken);
    }
    return d;
  }
  static async login(email, password) {
    const r = await AuthClient.client().post("/login", {
      email,
      password,
    });
    const { user, accessToken, refreshToken } = r.data;
    return {
      user,
      accessToken,
      refreshToken,
    };
  }
  static async logout() {
    await AuthClient.client().get("/logout");
  }
  static async checkEmail(email) {
    const res = await AuthClient.client().post("/check_email", {
      email,
    });
    return res;
  }

  static async loginSSO(email = "") {
    if (email.split("@")[1] === "example.com") {
      window.location.href = `http://localhost:5101/auth/sso/login?domain=example.com`;
      return;
    }
    try {
      await AuthClient.client().get("/sso/login", {
        params: {
          email,
        },
      });
    } catch (error) {
      console.log(error.response);
      if (error.response && [301, 302].includes(error.response.status)) {
        const redirectUrl = error.response.headers.location;
        return window.open(redirectUrl);
      } else {
        console.log("Erro SSO Não Identificado ::", error);
        throw error;
      }
    }
  }

  //create account
  static async createAccount({
    email,
    name,
    lastname,
    companyName,
    currency,
    usersNum,
    phone,
    erp,
  }) {
    const res = await AuthClient.client().post("/register", {
      email,
      name,
      lastname,
      phone,
      companyName,
      currency,
      usersNum,
      erp,
    });
    return res;
  }
  static async checkCreationEmail(email, code) {
    const res = await AuthClient.client().post("/activate", {
      email,
      code,
    });
    return res;
  }
  static async sendCreationEmail(email) {
    const res = await AuthClient.client().post("/send-confirm-email", {
      email,
    });
    return res;
  }

  //esqueceu a senha
  static async sendCodeToEmailForgotPassword(email) {
    const res = await AuthClient.client().post("/forgot_password", {
      email,
    });
    return res.data;
  }
  static async checkCodeForgotPassword(code, email) {
    const res = await AuthClient.client().post("/forgot_password/check-code", {
      email,
      code,
    });
    return res.data;
  }
  static async changePassword(code, email, newPassword) {
    const res = await AuthClient.client().post("/redefine_password", {
      email,
      code,
      newPassword,
    });
    return res.data;
  }

  //convites
  static async checkInvite(code, email) {
    const res = await AuthClient.client().get("/inviting/check", {
      params: {
        code,
        email,
      },
    });
    return res.data;
  }

  static async acceptInvite({
    code,
    account,
    email,
    password,
    name,
    lastname,
  }) {
    const res = await AuthClient.client().post(`/inviting/${code}/accept`, {
      account,
      code,
      email,
      password,
      name,
      lastname,
    });
    return res.data;
  }
}

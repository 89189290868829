import { Box, CircularProgress, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { APP_BASE_URL, BASE_URL } from "../../api/urls";
import NextButton from "../../components/buttons/NextButton";
import TextButton from "../../components/buttons/TextButton";
import TextInput from "../../components/inputs/text-input";
import ActionsFooter from "../../components/layout/ActionsFooter";
import Content from "../../components/layout/Content";
import ContentHeader from "../../components/layout/ContentHeader";

function checkEmailDomain(domain) {
  // Expressão regular para validar o domínio
  const regexDomain = /^[a-zA-Z0-9.-]+(\.[a-zA-Z]{2,3})+$/;

  // Testar se o domínio corresponde à expressão regular
  return regexDomain.test(domain);
}

const LoginSSO = () => {
  document.title = "NotePay: Login SSO";

  const [searchParams] = useSearchParams();
  const { pathname, search } = useLocation();

  const domain = searchParams.get("domain");
  const redirect = searchParams.get("redirect");
  const fromRN = searchParams.get("fromRN");

  const loginStatus = searchParams.get("status");
  const username = searchParams.get("user_name");
  const accessToken = searchParams.get("token");

  const navigate = useNavigate();
  const [value, setValue] = useState(
    window.localStorage.getItem("SSODomain") || domain || ""
  );
  const [error, setError] = useState(null);
  const [redirecting, setRedirecting] = useState(false);

  function redirectToApp() {
    window.localStorage.setItem("SSODomain", value);
    setRedirecting(true);
    setTimeout(() => {
      window.location.replace(redirect || APP_BASE_URL);
    }, 1000);
  }

  useEffect(() => {
    if (loginStatus === "success") {
      redirectToApp();
      if (accessToken) {
        window.ReactNativeWebView?.postMessage(
          JSON.stringify({
            status: loginStatus,
            token: accessToken,
            username,
          })
        );
      }
    }
  }, [loginStatus, accessToken, username]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!checkEmailDomain(value)) {
      setError("Insira um domínio válido. Ex: notepay.com");
      return;
    }
    const url = `${BASE_URL}/auth/sso/login?domain=${value}`;
    setRedirecting(true);
    window.location.replace(url);
  };

  const handleChangeText = (text = "") => {
    if (text?.includes("@")) return;
    setValue(text);
    setError(null);
  };

  return (
    <Content>
      <ContentHeader
        title={
          redirecting && username ? `Olá, ${username}` : "Login único (SSO)"
        }
        subtitle={!redirecting && "Insira o domínio do seu e-mail corporativo"}
      />
      {redirecting ? (
        <Box
          display={"flex"}
          flexDirection={"column"}
          alignItems={"center"}
          justifyContent={"center"}
          height={200}
        >
          <CircularProgress />
          <Typography
            mt={2}
            textAlign={"center"}
            fontWeight={"500"}
            color={"text.secondary"}
          >
            Entrando...
          </Typography>
        </Box>
      ) : (
        <form onSubmit={handleSubmit}>
          <TextInput
            autoFocus
            required
            value={value}
            onChange={handleChangeText}
            clearError={() => setError("")}
            error={error}
            label={"Domínio"}
            margin={"normal"}
            helperText={error || ""}
            placeholder={"minha_empresa.com"}
            LeftIcon={() => <Typography fontWeight={"600"}>@</Typography>}
          />
          <ActionsFooter
            leftContent={
              !fromRN && (
                <TextButton onClick={() => navigate(-1)}>
                  Voltar ao login
                </TextButton>
              )
            }
            rightContent={<NextButton>Entrar</NextButton>}
          />
        </form>
      )}
    </Content>
  );
};

export default LoginSSO;

import { Button } from "@mui/material";
import React from "react";

function TextButton({ children, onClick, disabled, disableElevation, sx }) {
  return (
    <Button
      disabled={disabled}
      onClick={onClick}
      variant="text"
      size="large"
      sx={{
        ...sx,
        transition: "none",
      }}
    >
      {children || ""}
    </Button>
  );
}

export default TextButton;

import { CheckCircleOutlineOutlined, InfoOutlined } from "@mui/icons-material";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  FormHelperText,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import Lottie from "react-lottie";
import { useNavigate, useSearchParams } from "react-router-dom";
import validator from "validator";
import { AuthAPIGateway } from "../../api/auth";
import ModalErrorBase from "../../components/ModalErrorBase";
import NextButton from "../../components/buttons/NextButton";
import TextButton from "../../components/buttons/TextButton";
import EmailChip from "../../components/chips/EmailChip";
import PasswordInput from "../../components/inputs/PasswordInput";
import TextInput from "../../components/inputs/text-input";
import ActionsFooter from "../../components/layout/ActionsFooter";
import Content from "../../components/layout/Content";
import ContentHeader from "../../components/layout/ContentHeader";
import { useAuth } from "../../context/AuthContext";
import { defaultErrorText, passwordRuleText } from "../../utils/texts";

const AcceptInvite = () => {
  document.title = "Aceitar convite | NotePay";
  const navigate = useNavigate();

  const pass1 = useRef();
  const pass2 = useRef();

  const [searchParams, setSearchParams] = useSearchParams();

  const code = searchParams.get("code") || "";
  const email = searchParams.get("email") || "";

  const { setLoading: setOverlayLoading, loading: loadingOverlay } = useAuth();

  const [checkError, setCheckError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [companyName, setCompanyName] = useState("");
  const [accountId, setAccountId] = useState("");
  const [name, setName] = useState("");
  const [lastname, setLastname] = useState("");
  const [pass, setPass] = useState("");
  const [checkPass, setCheckPass] = useState("");

  const [succeeded, setSucceeded] = useState(false);

  const [visiblePass, setVisiblePass] = useState(false);

  const [registerError, setRegisterError] = useState(null);

  const [errors, setErrors] = useState({
    name: null,
    pass: null,
    checkPass: null,
  });

  const handleSubmit = async (e) => {
    e.preventDefault();

    let fieldsErrors = {
      name: "",
      pass: "",
      checkPass: "",
    };
    if (!name?.trim()) {
      fieldsErrors.name = "Campo obrigatório";
    }
    if (!pass?.trim()) {
      fieldsErrors.pass = "Campo obrigatório";
    }
    if (!checkPass?.trim()) {
      fieldsErrors.checkPass = "Campo obrigatório";
    }
    if (
      !validator.isStrongPassword(pass?.trim(), {
        minLength: 8,
        minUppercase: 1,
        minSymbols: 1,
      })
    ) {
      fieldsErrors.pass = passwordRuleText;
      pass1.current?.focus();
      setCheckPass("");
    }
    if (checkPass?.trim() !== pass?.trim()) {
      fieldsErrors.checkPass = "Senhas não correspondem";
      setCheckPass("");
      if (!Boolean(fieldsErrors.pass)) {
        pass2.current?.focus();
      }
    }
    let ok = true;
    Object.keys(fieldsErrors).forEach((field) => {
      if (Boolean(fieldsErrors[field])) {
        setError(field, fieldsErrors[field]);
        ok = false;
      }
    });
    if (!ok || !accountId || !email || !code) return;
    setOverlayLoading(true);
    try {
      await AuthAPIGateway.acceptInvite({
        account: accountId,
        code,
        email,
        lastname,
        name,
        password: pass,
      });
      setSucceeded(true);
    } catch (error) {
      setRegisterError(error?.response?.data?.message || defaultErrorText);
    } finally {
      setOverlayLoading(false);
    }
  };

  const setError = (error, value) => {
    setErrors((prev) => ({
      ...prev,
      [error]: value,
    }));
  };

  const checkInvite = async () => {
    setLoading(true);
    setCheckError(null);
    try {
      const data = await AuthAPIGateway.checkInvite(code, email);
      setName(data?.name);
      setLastname(data?.lastname);
      setAccountId(data?.account?._id);
      setCompanyName(data?.account?.name);
      searchParams.set("email", data?.email);
      setSearchParams(searchParams);
    } catch (error) {
      setCheckError(error?.response?.data?.message || defaultErrorText);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    checkInvite();
  }, []);

  return (
    <>
      <Content>
        {loading ? (
          <Box
            display={"flex"}
            flexDirection={"column"}
            alignItems={"center"}
            justifyContent={"center"}
            height={300}
          >
            <CircularProgress />
          </Box>
        ) : checkError ? (
          <Box
            display={"flex"}
            flexDirection={"column"}
            alignItems={"center"}
            justifyContent={"center"}
            height={300}
          >
            <InfoOutlined
              fontSize="large"
              sx={{ fontSize: "3rem" }}
              color="error"
            />
            <Typography textAlign={"center"} mt={2} fontWeight={"500"}>
              {checkError}
            </Typography>
            <Button sx={{ mt: 2 }} onClick={checkInvite}>
              Tentar novamente
            </Button>
          </Box>
        ) : (
          <>
            {succeeded ? (
              <>
                <Box mt={2} />
                <ContentHeader
                  Icon={CheckCircleOutlineOutlined}
                  iconColor={"success"}
                  title={
                    <>
                      <Typography
                        fontSize={"1.1rem"}
                        color={"text.secondary"}
                        variant="inherit"
                      >
                        Perfeito, {name}.
                      </Typography>
                      <Typography mt={1} fontWeight={"500"} variant="inherit">
                        Sua conta foi criada com sucesso.
                      </Typography>
                    </>
                  }
                  subtitle="Acesse a plataforma e comece a gerenciar suas despesas agora mesmo."
                />
                <ActionsFooter
                  rightContent={
                    <NextButton
                      type="button"
                      onClick={() => {
                        navigate(`/?step=1&email=${email}`);
                      }}
                    >
                      Fazer login
                    </NextButton>
                  }
                />
              </>
            ) : (
              <>
                <Box mb={2} display={"flex"} justifyContent={"center"}>
                  <EmailChip size="small" email={email} editable={false} />
                </Box>
                <ContentHeader
                  title={
                    <>
                      <Typography
                        fontSize={"1.1rem"}
                        color={"text.secondary"}
                        variant="inherit"
                      >
                        Convite para se juntar à equipe:
                      </Typography>
                      <Typography mt={1} fontWeight={"600"} variant="inherit">
                        {companyName}
                      </Typography>
                    </>
                  }
                  subtitle="Insira as suas informações abaixo para concluir o seu cadastro "
                />
                <form onSubmit={handleSubmit}>
                  <TextInput
                    autoFocus
                    value={name}
                    onFocus={() => setError("name", null)}
                    onChange={setName}
                    margin={"dense"}
                    label={"Primeiro nome"}
                    helperText={errors.name || ""}
                    error={Boolean(errors.name)}
                    required
                  />
                  <TextInput
                    value={lastname}
                    onChange={setLastname}
                    margin={"normal"}
                    label={"Último nome"}
                  />
                  <PasswordInput
                    inputRef={pass1}
                    disableVisibilityButton
                    controlledType={visiblePass ? "text" : "password"}
                    clearError={() => setError("pass", null)}
                    error={errors.pass}
                    value={pass}
                    onChange={setPass}
                    label={"Senha"}
                  />
                  <PasswordInput
                    inputRef={pass2}
                    disableVisibilityButton
                    controlledType={visiblePass ? "text" : "password"}
                    clearError={() => setError("checkPass", null)}
                    error={errors.checkPass}
                    value={checkPass}
                    onChange={setCheckPass}
                    label={"Confirme a senha"}
                  />
                  {!errors.pass && (
                    <FormHelperText>{passwordRuleText}</FormHelperText>
                  )}
                  <FormControlLabel
                    sx={{ mt: 1 }}
                    control={
                      <Checkbox
                        checked={visiblePass}
                        onClick={() => setVisiblePass(!visiblePass)}
                      />
                    }
                    label="Mostrar senhas"
                  />
                  <ActionsFooter
                    rightContent={
                      <NextButton disabled={loadingOverlay}>
                        {loadingOverlay ? "Cadastrando..." : "Cadastrar"}
                      </NextButton>
                    }
                    leftContent={
                      <TextButton
                        disabled={loadingOverlay}
                        onClick={() => {
                          navigate(`/?step=0`);
                        }}
                      >
                        Voltar para login
                      </TextButton>
                    }
                  />
                </form>
              </>
            )}
          </>
        )}
        <ModalErrorBase
          open={Boolean(registerError)}
          onClose={() => setRegisterError(null)}
          title={"Erro ao cadastrar contra"}
          subtitle={registerError || ""}
        />
      </Content>
      {succeeded && (
        <Box
          display={{ xs: "none", lg: "inline" }}
          sx={{
            position: "fixed",
            top: 0,
            right: -50,
            zIndex: 0,
          }}
        >
          <Lottie
            width={500}
            height={500}
            style={{ transform: "rotate(-30deg)" }}
            isClickToPauseDisabled
            options={{
              autoplay: true,
              loop: false,
              animationData: require("../../assets/lotties/party.json"),
            }}
          />
        </Box>
      )}
    </>
  );
};

export default AcceptInvite;

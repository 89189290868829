import { InfoOutlined } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  Typography,
} from "@mui/material";
import React from "react";

function ModalErrorBase({ open, onClose, title, subtitle = "" }) {
  return (
    open && (
      <Dialog
        fullWidth
        maxWidth="xs"
        open={open}
        PaperProps={{
          sx: {
            position: "relative",
            borderRadius: 10,
            bgcolor: "elevation1.main",
          },
        }}
        TransitionProps={{
          unmountOnExit: true,
        }}
        onClose={onClose}
      >
        <DialogContent
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            pb: 10,
            pt: 4,
          }}
        >
          <InfoOutlined
            fontSize="large"
            sx={{ mb: 2, fontSize: "4rem" }}
            color="error"
          />
          <Typography
            gutterBottom
            fontWeight={600}
            fontSize={"1.8rem"}
            textAlign={"center"}
          >
            {title}
          </Typography>
          <DialogContentText textAlign={"center"}>{subtitle}</DialogContentText>
          <Button
            variant="outlined"
            sx={{ mt: 4, height: 60, fontSize: "18px" }}
          >
            Fechar
          </Button>
        </DialogContent>
      </Dialog>
    )
  );
}

export default ModalErrorBase;

import { Box } from "@mui/material";
import React, { useEffect } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import Container from "./components/layout/Container";
import LoadingOverlay from "./components/layout/LoadingOverlay";
import { useAuth } from "./context/AuthContext";
import AcceptInvite from "./pages/accept-invite/AcceptInvite";
import CreateAccount from "./pages/create-account/CreateAccount";
import ForgotPassword from "./pages/forgot-password/ForgotPassword";
import LoginSSO from "./pages/login-sso/LoginSSO";
import Login from "./pages/login/Login";

function Router(props) {
  const { loading } = useAuth();

  const navigate = useNavigate();
  const SSODomain = window.localStorage.getItem("SSODomain");

  useEffect(() => {
    if (SSODomain) {
      navigate(`/login-sso?domain=${SSODomain || ""}`);
      return;
    }
  }, []);

  return (
    <Box
      flex={1}
      width={"100%"}
      display={"flex"}
      flexDirection={"column"}
      p={2}
      paddingTop={{ sm: "90px", xs: "10px" }}
      pb={10}
      alignItems={"center"}
    >
      <Container>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="login-sso" element={<LoginSSO />} />
          <Route path="forgot-password" element={<ForgotPassword />} />
          <Route path="create-account" element={<CreateAccount />} />
          {/* <Route
            path="confirm-creation-email"
            element={<ConfirmCreationEmail />}
          /> */}
          <Route path="accept-invite" element={<AcceptInvite />} />
        </Routes>
      </Container>
      {loading && <LoadingOverlay />}
    </Box>
  );
}

export default Router;
